import {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useMediaQuery,
  VStack
} from '@chakra-ui/react';

import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/raleway/700.css';
import '@fontsource/raleway/400.css';


import { WinnerPrize, WinnerPrizeDetail } from '@interfaces/Winners';
import {
  getWinnerPrizesDetailReq,
  getWinnerPrizesReq,
} from '@services/winner_prize';
import { useEffect, useState } from 'react';
import '@fontsource/montserrat';

export default function WinnerPrizes() {
  const SHOW_PRIZE_MESSAGE = false;
  const [winners, setWinners] = useState<WinnerPrize[]>();
  const [winnersPrize, setWinnersPrize] = useState<WinnerPrizeDetail[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWinner, setSelectedWinner] = useState<WinnerPrize | null>(
    null
  );
  const [isLargerThan400] = useMediaQuery('(min-width: 400px)');
  const { onClose } = useDisclosure();

  const handleSelectWinnerPrize = (winner: WinnerPrize) => {
    setSelectedWinner(winner);
    setIsLoading(true);
    setWinnersPrize([]);
    getWinnerPrizesDetailReq({
      idPrize: winner.idPrize,
      type: winner.type,
    }).then((value) => {
      if (value != undefined) {
        setWinnersPrize(value);
      }
      setIsLoading(false);
    });
  };

  const handleOnCloseModal = () => {
    onClose();
    setSelectedWinner(null);
  };

  useEffect(() => {
    if (!SHOW_PRIZE_MESSAGE) {
      getWinnerPrizesReq().then((value) => {
        if (value != undefined) {
          setWinners(value.sort((a, b) => b.idPrize - a.idPrize));
        }
      });
    }
  }, []);

  const renderExibitionInfos = () => {
    if (isLargerThan400) {
      return (
        <TableContainer>
          <Table variant="striped">
            <TableCaption>
              <Text
                fontFamily={'Raleway'}
                fontWeight={400}
                color={'#1D1D1D'}
              >
                Informações do(s) {winnersPrize?.length} ganhadore(s)
              </Text>
            </TableCaption>
            <Thead>
              <Tr>
                <Th><Text
                  fontFamily={'Montserrat'}
                  fontWeight={700}
                  color={'#1D1D1D'}
                >Login</Text></Th>
                <Th><Text
                  fontFamily={'Montserrat'}
                  fontWeight={4700}
                  color={'#1D1D1D'}
                >Status</Text></Th>
                <Th><Text
                  fontFamily={'Montserrat'}
                  fontWeight={700}
                  color={'#1D1D1D'}
                >Data</Text>  </Th>
              </Tr>
            </Thead>
            <Tbody>
              {winnersPrize
                ?.sort(function (a, b) {
                  const now = new Date();
                  const aDate =
                    a.dt_delivery != null ? new Date(a.dt_delivery) : now;
                  const bDate =
                    b.dt_delivery != null ? new Date(b.dt_delivery) : now;
                  return aDate.getTime() - bDate.getTime();
                })
                .map((e) => {
                  let date = '';
                  if (e.dt_delivery != null && e.dt_delivery != undefined) {
                    date = new Date(e.dt_delivery).toLocaleDateString('pt-BR');
                  }
                  return (
                    <Tr>
                      <Td><Text
                        fontFamily={'Raleway'}
                        fontWeight={400}
                        color={'#1D1D1D'}
                      >{e.login}</Text></Td>
                      <Td><Text
                        fontFamily={'Raleway'}
                        fontWeight={400}
                        color={'#1D1D1D'}
                      >{e.status}</Text></Td>
                      <Td><Text
                        fontFamily={'Raleway'}
                        fontWeight={400}
                        color={'#1D1D1D'}
                      >{date}</Text></Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
      );
    }
    return (
      <VStack>
        {winnersPrize
          ?.sort(function (a, b) {
            const now = new Date();
            const aDate = a.dt_delivery != null ? new Date(a.dt_delivery) : now;
            const bDate = b.dt_delivery != null ? new Date(b.dt_delivery) : now;
            return aDate.getTime() - bDate.getTime();
          })
          .map((e, index) => {
            let date = '';
            if (e.dt_delivery != null && e.dt_delivery != undefined) {
              date = new Date(e.dt_delivery).toLocaleDateString('pt-BR');
            }
            return (
              <VStack alignItems="start" w="full" key={`winner_${index}`}>
                <HStack>
                  <Text fontWeight="bold">Login: </Text> <Text>{e.login}</Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">Status: </Text>{' '}
                  <Text>{e.status}</Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">Data: </Text> <Text>{date}</Text>
                </HStack>
                <Divider orientation="horizontal" size="2xl" />
              </VStack>
            );
          })}
      </VStack>
    );
  };

  if (SHOW_PRIZE_MESSAGE) {
    return (
      <Box background="linear-gradient(180deg, #FFFFFF 48.75%, rgba(255, 255, 255, 0) 153.44%);">
        <Flex w="full" h="full">
          <Container maxW={{ base: 'full', md: '9xl', lg: '8xl' }}>
            <VStack spacing={5}>
              <Heading
                fontSize={{ base: '24px', md: '32px', lg: '44px' }}
                p={[5, 5, 5, 5]}
                textAlign="center"
                fontFamily={'Montserrat'}
                fontWeight={700}
                color={'#1D1D1D'}
              >
                GANHADORES DA PROMOÇÃO
              </Heading>

              <SimpleGrid columns={2} spacing={[5, 5, 5, 80]}>
                <Center>
                  <Text
                    fontSize={{ base: '14px', md: '18px', lg: '26px' }}
                    align="center"
                  >
                    O sortei para os ganhadores desta promoção será realizado na
                    data DD/MM/YYYY!
                  </Text>
                </Center>
              </SimpleGrid>
            </VStack>
          </Container>
        </Flex>
      </Box>
    );
  }

  return (
    <>
      <Modal
        size="full"
        isOpen={selectedWinner != null}
        onClose={handleOnCloseModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center"><Text
              fontFamily={'Montserrat'}
              fontWeight={700}
              color={'#1D1D1D'}
              >{selectedWinner?.title}</Text></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading ? (
              <VStack>
                <Spinner size="xl" speed="0.65s" />
                <Text fontSize="sm">aguarde</Text>
              </VStack>
            ) : (
              renderExibitionInfos()
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box background="linear-gradient(180deg, #FFFFFF 48.75%, rgba(255, 255, 255, 0) 153.44%);">
        <Flex w="full" h={"full"} flexDir="column">
          <VStack spacing={5} w="full" h="full"
            pb={[-2,6]}>
            <HStack
              alignItems="center"
              justifyContent={'end'}
              w={'full'}
              h={['12vh', '15vh']}
              
            >
              <VStack
                position="absolute"
                marginLeft="auto"
                margin-right="auto"
                left={0}
                right={0}
                zIndex={0}
                spacing={1}
                pt={[0, 20]}
              >
                <Heading
                  pb={[0, 6]}
                  fontSize={{ base: '30px', md: '40px', lg: '45px' }}
                  textAlign={'center'}
                  fontFamily={'Montserrat'}
                  color={'#7D0C12'}
                  fontWeight={700}
                  lineHeight={'100%'}
                >
                  Ganhadoras da Promoção
                </Heading>
                <Text
                  pt={[3,0]}
                  pb={[0, 2]}
                  fontSize={['15px', '17px', '19px', '22px']}
                  align="center"
                  color="#1D1D1D"
                  fontFamily={'Raleway'}
                  fontWeight={400}
                >
                  Selecione o prêmio para ver as ganhadoras:
                </Text>
              </VStack>
            </HStack>
          </VStack>
          <Flex
            pb={14}
            mx={['12vw', '18vw', '10vw', '12vw', '17vw', '20vw']}
            flexFlow={['row wrap']}
            justifyContent={['space-evenly']}
            alignContent="space-around"
            alignItems="center"
          >
            {winners?.map((winner) => {
              return (
                <Image
                  key={winner.idPrize}
                  w={['145', '150', '200', '250', '270', '297']}
                  h={['130', '138', '185', '231', '241', '275']}

                  my={["1vh"]}
                  mx={['0vw']}
                  onClick={() => handleSelectWinnerPrize(winner)}
                  cursor="pointer"
                  _hover={{
                    shadow: 'sm',
                  }}
                  borderRadius="5%"
                  src={winner.description}
                />
              );
            })}
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
