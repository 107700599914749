import {
  Container,
  Box,
  VStack,
  Text,
  Link,
  HStack,
  StackDivider,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/raleway/700.css';
import '@fontsource/raleway/400.css';
import { useRouter } from 'next/router';


export default function Footer() {
  const router = useRouter();
  return (
    <Box
      bgColor={'#812679'}
      as={motion.div}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition="1s linear"
      viewport={{ once: true }}
    >
      <Container maxW={{ base: 'full', md: '5xl', xl: '1690px' }} py={8}>
        <VStack spacing={5}>
          <VStack display={{ base: 'flex', md: 'none' }}>
            <Link
              fontSize={['20px', '30px']}
              fontWeight={700}
              color="white"
              fontFamily={'Montserrat'}
              _hover={{ textDecor: 'underline' }}
              onClick={() => {
                router.push('/faq');
              }}
            >
              Dúvidas?
            </Link>
            <Link
              fontSize={['20px', '30px']}
              fontWeight={700}
              color="white"
              fontFamily={'Montserrat'}
              _hover={{ textDecor: 'underline' }}
              onClick={() => {
                router.push('/terms');
              }}

            >
              Política de privacidade
            </Link>
          </VStack>
          <HStack
            display={{ base: 'none', md: 'flex' }}
            align="stretch"
            spacing={2}
          >
            <Link
              pr={2}
              fontSize={['20px', '30px']}
              fontWeight={700}
              color="white"
              fontFamily={'Montserrat'}
              _hover={{ textDecor: 'underline' }}
              onClick={() => {
                router.push('/faq');
              }}

            >
              Dúvidas?
            </Link>
            <StackDivider
              alignSelf={'center'}
              w="4px"
              h="26px"
              bgColor="white"
            />
            <Link
              pl={2}
              fontSize={['20px', '30px']}
              fontWeight={700}
              fontFamily={'Montserrat'}
              color="white"
              _hover={{ textDecor: 'underline' }}
              onClick={() => {
                router.push('/terms');
              }}
            >
              Política de privacidade
            </Link>


          </HStack>
          <Text
            fontWeight={400}
            fontFamily={'Inter'}
            color="white"
            fontSize={['8px', '8px', '8px', '10px', '12px']}
            textAlign={['justify', 'center']}
          >
            Promoção válida da 00:01 do dia 01/05/2023 até 23:59 do dia 31/05/2023. Data do Sorteio: 07/06/2023 • Data da Apuração: Dia 18/06 as 16:00. CERTIFICADO DE AUTORIZAÇÃO SRE/ME Nº 04.026527/2023. Promoção válida apenas para compras realizadas no site e no Whatsapp oficial Meno Aliv.
          </Text>
          <Link
            pl={2}
            fontSize={['8px', '10px', '12px']}
            fontWeight={700}
            fontFamily={'Montserrat'}
            color="white"
            _hover={{ textDecor: 'underline' }}
            isExternal={true}
            href={"https://maquinadepromos.com.br/?client=menoaliv"}
          >
            Desenvolvido por Máquina de Promos
          </Link>
        </VStack>
      </Container>
    </Box >
  );
}
