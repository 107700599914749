import {
  Flex,
  Text,
  Button,
  Box,
  HStack,
  Link,
  Icon,
} from '@chakra-ui/react';
import { MdMenu } from 'react-icons/md';
import UserProfile from '@components/UserProfile';
import { useRouter } from 'next/router';
import { motion } from 'framer-motion';
import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';

import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/raleway/700.css';
import '@fontsource/raleway/400.css';


export default function Header({
  onOpen,
  ...rest
}: {
  onOpen: any;
  rest?: any;
}) {
  const { data: _session, status } = useSession();
  const [isOnTop, setIsOnTop] = useState(false);
  const router = useRouter();

  const handleScroll = () => {
    const scrollPosition = window.scrollY; // => scroll position
    setIsOnTop(scrollPosition == 0);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Flex
      position="sticky"
      top="0"
      px={[5, 10, 15, 20]}
      height={status === 'unauthenticated' ? '80px' : '90px'}
      zIndex="1"
      alignItems="center"
      backgroundColor={isOnTop ? undefined : '#F0F0F0'}
      backdropFilter={isOnTop ? undefined : 'saturate(180%) blur(5px)'}
      opacity={0.95}
      justifyContent="space-between"
      {...rest}
    >
      <Icon
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        color="white"
        w={6}
        h={6}
        size="lg"
        aria-label="open menu"
        as={MdMenu}
        zIndex={1}
      />

      <HStack
        zIndex={1}
        spacing={6}
      >
        {status === 'unauthenticated' && (
          <Box
            display={{ base: 'none', md: 'flex' }}
            as={motion.div}
            dragConstraints={{ left: -100, right: 100 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition="0.05s linear"
            pr={5}
          >
            <Button
              borderRadius={60}
              pl={10}
              pr={10}
              bgColor={'#F6506B'}
              _hover={{ bg: 'pink.700' }}
              _active={{ bg: 'pink.900' }}
              onClick={() => {
                router.push('/login');
              }}
              size="lg"
            >
              <Text
                fontFamily={'Montserrat'}
                fontSize={'18px'}
                fontWeight={700}
                color="white"
              >
                Quero participar
              </Text>
            </Button>
          </Box>
        )}
        <Link
          fontWeight={400}
          _hover={{ textDecor: 'underline' }}
          display={{ base: 'none', md: 'flex' }}
          onClick={() => {
            router.push("/regulations");
          }}
          color="black"
          fontFamily={'Montserrat'}
        >
          Regulamento
        </Link>

        <Link
          fontWeight={400}
          color="black"
          _hover={{ textDecor: 'underline' }}
          display={{ base: 'none', md: 'flex' }}
          onClick={() => {
            router.push("/faq");
          }}
          fontFamily={'Montserrat'}
        >
          Dúvidas?
        </Link>
      </HStack>
      <UserProfile />
    </Flex>
  );
}
