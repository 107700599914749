import Header from "@components/Header";
import Sidebar from "@components/Sidebar";
import Footer from "@components/Footer";
import { Box, Drawer, DrawerContent, useDisclosure } from "@chakra-ui/react";

export default function Layout({ children, background }: { children: any; background?: string  }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" background={background ??"linear-gradient(180deg, #F0F0F0 0%, #F0F0F0 13.54%, #FFFFFF 55.73%);"}>
      {/* <Sidebar
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      /> */}
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <Sidebar onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Header onOpen={onOpen} />
      <Box>
        {children}
      </Box>
      <Footer />
    </Box>
  );
}
