import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import WinnerPrizes from '@components/WinnerPrizes';
import '@fontsource/raleway/700.css';
import '@fontsource/raleway/400.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/400.css';
import router from 'next/router';

import { useSession } from 'next-auth/react';


export default function MenoalivModule() {
  const { data: _session, status } = useSession();

  return (
    <>
      <Box
        py={[4, 6, 8, 8]}
        color="white"
        pl={{ sm: 'none', md: '5', lg: '8' }}
        pr={{ sm: 'none', md: '5', lg: '8' }}
      >
        <Container maxH={{ base: 'full' }} maxW={{ base: 'full' }} py={[2, 2, 2, 2]} textAlign="center"
        >
          <Stack
            align={'center'}
            direction={['column', 'row']}
          >
            <VStack
              spacing={[5, 5, 5, 5]}
              direction={['row']}
              justify="center"
              w={'full'}
              h={'full'}          >
              <Image
                pr={[0, 0]}
                pb={[0, 0]}
                align={'top'}
                w={['350', '500', '500', '600', '700', '800']}
                h={['262', '375', '375', '450', '525', '600']}
                src="/imgs/clube-menoaliv.svg" />
            </VStack>
            <VStack
              spacing={[5, 5, 5, 5]}
              direction={['row']}
              justify="center"
              w={'full'}
              h={'full'}
            >
              <Heading
                color={'#7D0C12'}
                letterSpacing={'1px'}
                lineHeight={'100%'}
                fontWeight={700}
                fontSize={['29px', '18px', '30px','38px', '45px', '53px']}
                textAlign={'center'}
                fontFamily={'Montserrat'}
              >
                Promoção
                <b style={{ color: '#F6506B' }}> Meno Aliv</b> <br /> Bem-estar
                Premiado
              </Heading>

              <Text
                w={'98%'}
                h={'full'}
                opacity={'80%'}
                textAlign={['justify', 'center']}
                fontSize={['17px','10px','14px','17px', '21px', '26px']}
                color={'#1D1D1D'}
                fontFamily={'Raleway'}
                fontWeight={400}
                lineHeight={'140%'}
              >
                Na compra de qualquer produto do site menoaliv.com.br durante o
                mês de maio, você ganha números da sorte para participar do
                sorteio dia 18/06/2023 e concorrer aos prêmios!
              </Text>
              <Image
                borderRadius={'15px'}

                width={['100%']}
                height={['100%']}
                src={'/imgs/meno.svg'}
              />
{status === 'unauthenticated' && (              <Center pt={['5', '0']} flexDir={'column'}>
                <Button
                  bgColor={'#F6506B'}
                  _hover={{ bg: 'pink.700' }}
                  _active={{ bg: 'pink.900' }}

                  pl={['8','9']}
                  pt={['4','5']}
                  pb={['4','5']}
                  pr={['8','9']}
                  
                  borderRadius={'43.5px'}
                  variant="solid"
                  onClick={() => {
                    router.push('/login');
                  }}
                  //   isLoading={loading}
                  loadingText="Carregando"
                  spinnerPlacement="start"
                >
                  <Text
                    fontWeight={700}
                    fontFamily={'Montserrat'}
                    lineHeight={'30.48px'}
                    color="#FFFFFF"
                    fontSize={['17px','13px','17px','17px', '21px', '26px']}
                    
                  >
                    Entrar
                  </Text>
                  
                </Button>
              </Center>)}
            </VStack>
          </Stack>
        </Container>
      </Box>
      <VStack width={'full'} bgColor={'#F4909A'} my={5} py={[3, 4, 5, 0]}>
        <Text
          pt={['2', '12']}
          pl={[9, 4]}
          pr={[9, 4]}
          textAlign={'center'}
          fontSize={['30px', '45px']}
          color={'#7D0C12'}
          fontFamily={'Montserrat'}
          fontWeight={700}
          lineHeight={'100%'}
        >
          Prêmios incríveis para as mamães!
        </Text>
        <HStack overflowY="hidden" overflowX="auto" mb={2} spacing={10}>
          <Image
            left={'224px'}
            top={'861px'}
            src="/imgs/premios-menoaliv.svg"
          />
        </HStack>
      </VStack>

      <VStack width={'full'} my={[5, 0, 0, 5]} py={5}>
        <Text
          p={4}
          pb={[3,6]}
          fontFamily="Montserrat"
          textAlign={'center'}
          fontSize={['30px', '45px']}
          color={'#7D0C12'}
          fontWeight={700}
          lineHeight={'100%'}
        >
          Como faço para ganhar?
        </Text>
        <Text
          pl={4}
          pr={4}
          pb={0}
          fontWeight={400}
          textAlign={['justify', 'center']}
          color={'#1D1D1D'}
          lineHeight={'120%'}
          fontFamily={'Raleway'}
          fontSize={['15px', '17px', '19px', '22px']}
          letterSpacing={'0.03em'}
        >
          A cada pote comprado, você tem{' '}
          <span style={{ fontWeight: '700' }}> +1 CHANCE DE GANHAR</span> um dos
          prêmios exclusivos.{' '}
          <span style={{ fontWeight: '700' }}> Compre e concorra!</span>
        </Text>
        <HStack overflowY="hidden" overflowX="auto" py={2} mb={2} spacing={10}>
          <Image
            width={['1281px', '1481px']}
            height={['113px', '365px']}
            left={'80px'}
            top={'1443px'}
            src="/imgs/menoaliv-produtos.svg"
            pt={0}
          />
        </HStack>
      </VStack>
      <WinnerPrizes />
    </>
  );
}
